.socials {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -0.3em;
  list-style-type: none;
  align-items: center;
  padding: 0;
  font-size: rem(20px);
  text-align: center;
  > li {
    display: inline-block;
  }

  a {
    border-radius: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 200ms ease-in-out;
    width: rem(40px);
    height: rem(40px);
    border-radius: 20rem;
    border: 0.1rem solid $dark;
    color: $white;
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
    background-color: $dark;
    svg {
      width: 1rem !important;
      height: 1rem !important;
    }

    &:hover {
      color: $dark;
      background-color: transparent;
    }
  }
}
