$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1200px;
$xxl: 1400px;

$primary: #51bda5;
$secondary: #e74b00;
$white: #fff;
$dark: #231f20;
$body-bg: #fff;
$body-color: #222222;
$gray-300: #f9f9f9;
$gray-400: #e9e9e9;
$gray-500: #cccccc;
$gray-600: #777777;
$gray-700: #222222;

$border-radius: 3rem;
$base-padding: 25rem;

$font-family-base: "Cairo", sans-serif;
$font-family-sub: "Roboto Slab", serif;

$grid-gutter-width: 1.875rem;

$border-radius-xl: 4px;
