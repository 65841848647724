.appImage {
  position: relative;
  padding-top: aspectRatio(1, 1);
  user-select: none;
  z-index: 2;
  overflow: hidden;
  animation: shimmer 1.2s ease-in-out infinite;
  &:after {
    content: "";
    background: $primary;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0rem;
    transition: 0.5s ease-in-out;
    transform-origin: bottom;
    transition-delay: 0.2s;
    z-index: 2;
  }

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.inViewJs-active {
    animation: none;

    &:after {
      opacity: 0;
    }
    > img {
      opacity: 1;
    }
  }

  &.is-radius {
    border-radius: $border-radius-xl;
  }

  &.is-1-1 {
    padding-top: aspectRatio(1, 1);
  }
  &.is-2-1 {
    padding-top: aspectRatio(2, 1);
  }
  &.is-1-2 {
    padding-top: aspectRatio(1, 2);
  }
  &.is-3-1 {
    padding-top: aspectRatio(3, 1);
  }
  &.is-4-3 {
    padding-top: aspectRatio(4, 3);
  }
}

@keyframes shimmer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
