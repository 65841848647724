* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

img {
  user-select: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: $primary;
  }
}
