.intro {
  &-item {
    position: relative;
    &__body {
      position: absolute;
      top: 0rem;
      inset-inline-start: 0rem;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    .appImage {
      height: rem(400px);
      img {
        filter: brightness(0.4);
      }
    }
  }
}

.services {
  &-icon {
    width: 3rem !important;
    height: 3rem !important;
  }
}

.culture {
  &-icon {
    width: rem(100px);
    height: rem(100px);
    border: 0.20rem solid $gray-600;
    .svgIcon {
      width: 3rem !important;
      height: 3rem !important;
    }
  }

  &-box{
    position: relative;
    &:not(:first-child)::after {
      content: "";
      position: absolute;
      top: 3.5rem;
      left: 70%;
      width: 60%;
      height: rem(3px);
      background-color: $gray-600;
      @media (max-width: $lg) {
        background-color: transparent;
      }
    }
  }
}

.brand {
  &-img{
    width: rem(180px);
    height: auto;
    margin: 0rem 1rem 1rem 1rem;
    .appImage {
      img {
        object-fit: contain;
      }
    }
  }
}